import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import "typeface-playfair-display"
import Img from "gatsby-image"
import style from "./home.module.css"

const MembershipInfo = ({ data }) => {
  return (
    <div>
      <SEO title="Membership" keywords={[`diet`, `dietriot`]} />
      <div>
        <section>
          <div class="relative bg-white overflow-hidden">
            <div class="max-w-7xl mx-auto">
              <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                <svg
                  class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                  fill="currentColor"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                  aria-hidden="true"
                >
                  <polygon points="50,0 100,0 50,100 0,100" />
                </svg>

                <div class="relative pt-6 px-4 sm:px-6 lg:px-8"></div>

                <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                  <div class="sm:text-center lg:text-left">
                    <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                      <span class="block xl:inline">
                        Mama, it’s time to drop diets{" "}
                      </span>
                      <span class="block text-drteal xl:inline">
                        and pick back up your life
                      </span>
                    </h1>
                    <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                      We help busy moms create a simple and peaceful wellness
                      journey without the chaos of on and off again dieting
                    </p>
                    <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                      <div class="sm:justify-center sm:self-center font-bold mr-4">
                        Join The Mama Well Community Now
                      </div>
                      <div class="rounded-md shadow">
                        <a
                          href="https://mailchi.mp/f70bc0b6351d/waitlist"
                          class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-drteal hover:bg-drteal-dark md:py-4 md:text-lg md:px-10"
                        >
                          Join the waitlist
                        </a>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
            <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <Img
                class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                fluid={data.brookeAlyssaCoffee.childImageSharp.fluid}
                alt=""
              />
            </div>
          </div>
        </section>

        <section class="bg-gray-50">
          <div class="container mx-auto">
            <div class="md:flex">
              <div class="flex-col max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex  lg:justify-between">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  <span class="block font-sans">
                    Hey Mama we see you. Maybe you’re tired of feeling...
                  </span>
                  <ul class="list-disc mb-10">
                    <li class="block tracking-normal mt-4 font-light max-w-2xl text-xl text-gray-500 lg:mx-auto">
                      Resentful you can’t even remember the last time you had
                      enough time or energy to workout or “eat right.” Why is
                      your health always last on the list?
                    </li>
                    <li class="block tracking-normal mt-4 font-light max-w-2xl text-xl text-gray-500 lg:mx-auto">
                      Uncomfortable in your body, avoiding girls nights, wearing
                      the swimsuit or getting into the pictures with your kids
                    </li>
                    <li class="block tracking-normal mt-4 font-light max-w-2xl text-xl text-gray-500 lg:mx-auto">
                      Devastated by what the scale says, hoping tomorrow it says
                      you’ve lost enough to eat dessert
                    </li>
                    <li class="block tracking-normal mt-4 font-light max-w-2xl text-xl text-gray-500 lg:mx-auto">
                      Anxious if you take your eye off the rules for a second
                      all your hard work will be lost
                    </li>
                    <li class="block tracking-normal mt-4 font-light max-w-2xl text-xl text-gray-500 lg:mx-auto">
                      Confused, wondering how in the heck you’re supposed to
                      find health without going on another diet and trying to
                      lose weight
                    </li>
                  </ul>
                </h2>
                <div class="mt-11">
                  <a
                    href="https://mailchi.mp/f70bc0b6351d/waitlist"
                    class="px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-drblue hover:bg-drblue-light"
                  >
                    Join the waitlist &rarr;
                  </a>
                </div>
              </div>
              <div class="flex flex-col w-full justify-self-center self-center mb-14">
                <div class="shadow-2xl border-white border-8 border-solid">
                  <Img fluid={data.membershipPic.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-drblue">
          <div class="container mx-auto py-24 flex justify-center">
            <div class="sm:w-4/5 lg:w-3/5">
              <p class="font-medium text-center text-white italic sm:text-2xl md:text-4xl block ">
                "Since joining the membership, I have been moving consistently &
                I have found regular movement has been a GAME CHANGER for my
                mental health and the way I feel in my body with goals of
                feeling stronger instead of smaller. My loved ones are also
                noticing and it feels so good!"
              </p>
              <p class="font-bold text-center text-white italic text-2xl block">
                &mdash; Current Member
              </p>
            </div>
          </div>
        </section>
        <section class="container mx-auto my-24">
          <div class="mt-14">
            <h4 class="text-xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Maybe you’ve already realized diets don’t work but you’re not sure
              what to do instead. Imagine a life if you could finally:
            </h4>
            <ul class="list-disc mb-10">
              <li>
                Add in simple healthy changes that don’t require a babysitter
              </li>
              <li>
                Felt so good in your mom bod so that you pick back up the parts
                of life you had put on hold waiting to lose the weight
              </li>
              <li>Let your body lead the conversation instead of the scale</li>
              <li>
                Saying yes to the things you were putting on hold until you
                achieved a “perfect” body, like that long overdue girls night
              </li>
              <li>
                Turn to two Registered Dietitians to give you support and
                guidance, along with a community of other moms
              </li>
            </ul>
            <a
              href="https://mailchi.mp/f70bc0b6351d/waitlist"
              class="px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-drblue hover:bg-drblue-light"
            >
              Join the waitlist &rarr;
            </a>
          </div>
        </section>
        <section class="bg-drblue">
          <div class="container mx-auto my-24 py-14 text-white">
            <h2 class="text-3xl font-extrabold tracking-tight text-white sm:text-4xl text-center">
              <span class="block">The Mama Well can help</span>
            </h2>
            <h4 class="text-center">
              Turning away from diets, counting calories and meal plans can be
              scary. You don’t have to do it alone.
            </h4>
            <p>
              The Mama Well is the community where busy moms come to catch their
              breath, fill up their cup and find a simple and peaceful path to
              health without jumping on and off a new diet or plan every few
              weeks.
            </p>
            <p>
              When you join the Mama Well, you will finally have a place to
              learn how to be healthy while also being the amazing mama you are.
              Plus, you’ll learn alongside and connect with other mamas who GET
              IT and can relate to how hard it is so you aren’t alone on this
              journey.
            </p>
            <p>
              You won’t have to worry about counting or tracking anything. You
              will be living a life where you enjoy the guac on the beach
              without worrying about whether or not you should get in the ocean,
              be in the picture, or take that vacation!
            </p>
          </div>
        </section>
        <section class="container mx-auto my-24">
          <h2 class="text-center text-3xl mt-20 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span class="block">
              Inside the Mama Well community, we learn how to make the WELL
              framework work in your life.
            </span>
          </h2>
          <h4 class="text-center mb-12">Be well at the mama well.</h4>
          <div class="grid lg:grid-cols-4 gap-4">
            <div class="bg-white p-4 shadow-lg relative sm:rounded-xl">
              <div class="flex justify-center mb-3">
                <span class="flex block font-bold text-white bg-drteal justify-center items-center text-center w-20 h-20 md:text-4xl rounded-l">
                  <span>W</span>
                </span>
              </div>
              <h5 class="text-center">Be Well</h5>
              <p>
                start adding in simple daily changes you can make while raising
                a family
              </p>
            </div>
            <div class="bg-white p-4 shadow-lg relative sm:rounded-xl">
              <div class="flex justify-center mb-3">
                <span class="flex block font-bold text-white bg-drteal justify-center items-center text-center w-20 h-20 md:text-4xl rounded-l">
                  <span>E</span>
                </span>
              </div>
              <h5 class="text-center">Empower</h5>
              <p>feel empowered and strong in your mom bod</p>
            </div>
            <div class="bg-white p-4 shadow-lg relative sm:rounded-xl">
              <div class="flex justify-center mb-3">
                <span class="flex block font-bold text-white bg-drteal justify-center items-center text-center w-20 h-20 md:text-4xl rounded-l">
                  <span>L</span>
                </span>
              </div>
              <h5 class="text-center">Learn</h5>
              <p>
                discover how to listen to your body even with the chaos of
                dieting
              </p>
            </div>
            <div class="bg-white p-4 shadow-lg relative sm:rounded-xl">
              <div class="flex justify-center mb-3">
                <span class="flex block font-bold text-white bg-drteal justify-center items-center text-center w-20 h-20 md:text-4xl rounded-l">
                  <span>L</span>
                </span>
              </div>
              <h5 class="text-center">Live</h5>
              <p>
                live a life free from counting calories and finally start living
                the life you were putting on hold
              </p>
            </div>
          </div>
        </section>
        <section class="bg-gray-50 py-24">
          <div class="container mx-auto">
            <h3 class="text-center text-4xl">The Mama Well</h3>
            <h4 class="text-center text-2xl font-light">
              where mamas come together to drop diets and pick up their life
            </h4>
            <p>
              <span class="font-bold">Daily affirmation texts:</span> to
              encourage you on the good &amp; bad days.
            </p>
            <p>
              <span class="font-bold">Monthly WELL challenges:</span> get
              guidance &amp; support on what to work through, so you don't feel
              overwhelmed and can lean on your community of moms.
            </p>
            <p>
              <span class="font-bold">Monthly Happy Hour Coaching Call:</span>{" "}
              bring your favorite beverage, share your wins &amp; struggles and
              get laser coaching from Brooke &amp; Alyssa on topics like body
              image, nutrition, &amp; exercise.
            </p>
            <p>
              <span class="font-bold">Monthly Coffee &amp; Question Call:</span>{" "}
              drop your questions in the membership or bring them live for
              Brooke &amp; Alyssa to answer. Don't forget your coffee!
            </p>
            <p>
              <span class="font-bold">Pop up member only giveaways:</span> we
              reward you with fun prizes just for showing up and doing the work!
              Who doesn't love free stuff?
            </p>
            <p>
              <span class="font-bold">
                Quarterly workshops &amp; guest experts:
              </span>{" "}
              This is perfect for the busy mama who wants to listen &amp; learn
              at your own pace on your own schedule. Topics include stopping
              guilt &amp; shame around food, listening to hunger &amp; fullness
              cues, how to handle changes in weight, how to improve body image,
              and adding in gentle nutrition &amp; joyful movement to a busy
              schedule.
            </p>
          </div>
        </section>
        <section>
          <div class="mb-8 md:flex justify-center">
            <div>
              <div className={style.monthlyPriceBox}>
                <h3 className={style.subscriptionTitle}>Monthly</h3>
                <span className={style.subscriptionPrice}>$67</span>
                <span className={style.subscriptionTime}>/month</span>
                <span className={style.subscriptionTime}>minimum 3 months</span>
                <span className={style.subscriptionSeparator}></span>

                <p className={style.featureList}>
                  (this is recommended for you if you have worked with a
                  dietitian in the past and is needing help sustaining the
                  habits you have built)
                </p>
                <a
                  class="block tracking-widest uppercase text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-drteal hover:bg-drteal-dark"
                  href="https://mailchi.mp/f70bc0b6351d/waitlist"
                >
                  Join the waitlist!
                </a>
              </div>
            </div>
            <div>
              <div className={style.yearlyPriceBox}>
                <h3 className={style.subscriptionTitle}>Yearly</h3>
                <span className={style.subscriptionPrice}>$670</span>
                <span className={style.subscriptionTime}>/year</span>
                <span className={style.subscriptionTime}>
                  payment plan available
                </span>
                <span className={style.subscriptionSeparator}></span>

                <p className={style.featureList}>
                  (this is recommended for you if you are new to ditching diets
                  and need the tools to start making peace with food and your
                  body)
                </p>
                <a
                  class="block tracking-widest uppercase text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-drteal hover:bg-drteal-dark"
                  href="https://mailchi.mp/f70bc0b6351d/waitlist"
                >
                  Join the waitlist!
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="bg-drblue">
          <div class="container mx-auto py-24 flex justify-center">
            <div class="sm:w-4/5 lg:w-3/5">
              <p class="font-medium text-center text-white italic text-3xl block ">
                I'm new in the membership, but I know I made the right choice in
                joining this community. My toddler asked for some of my food
                &amp; she ended up eating vegetables &amp; enjoying them. I
                chose to sit down and have a healthy lunch and because of that,
                she tried something new. It drove home the main reason I chose
                to join: I need to be comfortable eating in a way that I want
                them to mimic.
              </p>
              <p class="font-bold text-center text-white italic text-2xl block">
                &mdash; Current Member
              </p>
            </div>
          </div>
        </section>
        <section class="py-28 bg-drtan">
          <div class="grid lg:grid-cols-2 gap-4 container mx-auto">
            <div class="relative py-3 sm:max-w-xl sm:mx-auto ">
              <div class="p-8 flex-col relative">
                <h4 class="text-xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Hey there, a little about us...
                </h4>
                <p>
                  Hi we’re Brooke and Alyssa Miller, not related just redundant.
                  We’re two Registered Dietitians mamas who realized there is
                  hardly any support for moms who wanted to prioritize their
                  health without trying to keep up with the chaos that fad diets
                  and tracking demands.
                </p>
                <p>
                  After years of being Registered Dietitians and struggling to
                  heal our own relationship with food &amp; bodies, we knew we
                  wanted to help mamas just like you put into practice the steps
                  that helped us find a full and healthy life.
                </p>
                <p>
                  We wanted a peaceful place for you to come, fill up your cup
                  &amp; heal your mind, body &amp; soul at the well. We can’t
                  wait to cheer you on in this journey!
                </p>
              </div>
            </div>
            <div class="flex flex-col w-full">
              <div class="shadow-2xl border-white border-8 border-solid -skew-y-6">
                <Img
                  class="skew-y-6"
                  fluid={data.brookeAlyssaCoffee.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
        </section>
        <section class="container mx-auto my-24">
          <div class="md:flex bg-white p-6 rounded shadow-md">
            <h2 class="text-xl font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:text-center md:text-left md:mr-8">
              <span class="block">Frequently asked questions</span>
            </h2>
            <div>
              <p class="text-black text-xl font-medium">
                I’m a busy mom. How much time do I need to put into the
                membership to see results?
              </p>
              <p class="text-gray-500">
                We recommend spending 30 minutes per week inside the community.
                However, we completely understand busy mom life &amp; that there
                are some weeks that things come up completely out of our control
                and you may miss a week. Pick a day &amp; time that typically
                works best for you &amp; mark your calendar. The calls are all
                recorded, so if you miss a call, you can always catch a replay.
              </p>
              <p class="text-black text-xl font-medium">
                Will my investment pay off?
              </p>
              <p class="text-gray-500">
                We also care where our hard earned money goes and understand
                your investment needs to pay off. We surveyed our current
                members and 100% said they would recommend a friend. They also
                said they feel the value of the membership is higher than the
                current price. This membership is a much more affordable way to
                get support from two Registered Dietitians without the high cost
                of 1:1 coaching.
              </p>
              <p class="text-black text-xl font-medium">
                Is the monthly or annual membership right for me?
              </p>
              <p class="text-gray-500">
                If you have worked with a Registered Dietitian in the past and
                feel you are in the maintenance phase of intuitive eating, the
                monthly option is a great place to start. If you are new to
                intuitive eating and haven’t worked 1:1 with a Registered
                Dietitian, we highly encourage you to sign up for the annual
                membership. This journey is not a quick fix and you will be
                amazed at your results if you give it a full year. You and your
                children deserve this.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div class="bg-gray-50">
            <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
              <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                <span class="block">Your best life is waiting for you!</span>
                <span class="block text-drteal">What are you waiting for?</span>
              </h2>
              <div class="mt-8 lex lg:mt-0 lg:flex-shrink-0">
                <div class="inline-flex rounded-md shadow">
                  <a
                    href="https://mailchi.mp/f70bc0b6351d/waitlist"
                    class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-drteal hover:bg-drteal-dark"
                  >
                    Join the waitlist
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default MembershipInfo

export const query = graphql`
  query {
    membershipPic: file(relativePath: { eq: "brooke_and_alyssa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 564) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brookeAlyssaCoffee: file(relativePath: { eq: "brooke_alyssa_coffee.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
